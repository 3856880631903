<template>
    <div>
        <h2>（一）原料药情况</h2>
        
        <div>
            <el-select style="width: 100%" v-model="selectCheckedObj" multiple filterable remote reserve-keyword placeholder="请输入关键词以选择原料药"
                       :loading="loading" :remote-method="getSelectList" @change="selectChange" value-key="comCode">
                <el-option v-for="item in selectList" :key="item.comCode" :value="item" :label="item.comName"></el-option>
            </el-select>
        </div>
        
        <p class="center">表 7：产品原料药情况一览表</p>

        <table>
            <thead>
            <tr>
                <th>序号</th>
                <th>产品来源</th>
                <th>登记号</th>
                <th>企业</th>
                <th>药品名称</th>
                <th>英文</th>
                <th>规格</th>
                <th>更新日期</th>
                <th>与制剂共同审批结果</th>
                <th>状态</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in list" :key="index">
                <td>{{index + 1}}</td>
                <td>{{item.productSource}}</td>
                <td>{{item.registrationNumber}}</td>
                <td>{{item.compName}}</td>
                <td>{{item.comName}}</td>
                <td>{{item.comNameEn}}</td>
                <td>{{item.spec}}</td>
                <td>{{item.updateDate}}</td>
                <td>{{item.approvalResults}}</td>
                <td>{{item.remark}}</td>
            </tr>
            <tr v-if="list.length === 0">
                <td :colspan="10" class="no-data">暂无数据</td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        props: ['prefix'],
        data() {
            return {
                selectCheckedObj: [],
                selectChecked: [],
                selectList: [],
                loading: false,
                
                list: [],
            }
        },
        
        created() {
            this.getSelectList()
        },
        methods: {
            getSelectList(keyword) {
                this.loading = true
                this.selectList = []
                this.$api.get(`${this.prefix}/queryMaterialDrugsListByKeyword`, {keyword})
                    .then(res => {
                        this.selectList = res.data
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            selectChange() {
                this.list = []
                this.selectChecked = this.selectCheckedObj.map(item => item.comCode)
                if (this.selectChecked.length > 0) {
                    this.$api.post(`${this.prefix}/queryMaterialDrugsList`, this.selectChecked)
                        .then(res => {
                            this.list = res.data
                        })
                }
            },
        }
    }
</script>